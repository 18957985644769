<template>
  <Card title="Reset Password">
    <Form @submit="forgotPassword">
      <p>Enter your email address and we’ll send you a link to reset your password</p>
      <Input 
        id="email" 
        label="Email Address" 
        hasIcon=true 
        iconType="email" 
        type="email" 
        placeholder="Email"
        @change="updateData($event,'email')"
      />
      <Message :class="{error : this.error}">{{this.error}}{{this.message}}</Message>

      <SubmitButton class="large-spacer center-spacing">Continue</SubmitButton>
    </Form>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Form from '@/components/Form';
import Input from '@/components/Input';
import SubmitButton from '@/components/SubmitButton';
import Message from '@/components/Message';
import {eventBus} from '@/main.js';

//API
import { forgotPassword } from '@/services/api/users.js';

export default {
  components:{
     Card,Form,Input,SubmitButton,Message
  },
  data(){
    return{
      email: '',
      error: '',
      message: '',
    }
  },
  methods:{
    updateData(data,attr){
      this[attr] = data;
    },
    async forgotPassword(){
      this.error = '';

      eventBus.$emit('loader',true);
      try {
        await forgotPassword(this.email);
        eventBus.$emit('loader',false);
        this.$router.push({name: 'ResetPasswordConfirm', params:{ email: this.email}});
        
      } catch (error) {
        this.error = error.response.data.userMessage;
        eventBus.$emit('loader',false);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
form{
  p{
    max-width: 350px;
    text-align: center;
    padding: 1rem 0 2.5rem;
  }
}
</style>